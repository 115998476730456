var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { atom, selector, useRecoilState } from 'recoil';
import { STICK_BANNER_HEIGHT } from 'renderer/components';
import { HEADER_HEIGHT, LOCAL_STORAGE_KEY_ACCESS_TOKEN, LOCAL_STORAGE_KEY_AFTER_SCHEDULE_REGISTER_COMPLETE, LOCAL_STORAGE_KEY_COURSE_LIST_DRAWER, LOCAL_STORAGE_KEY_HIDE_BANNER_VISIBLE, LOCAL_STORAGE_KEY_REFRESH_TOKEN } from 'renderer/constants';
import { getWithExpiry, LocalStorage, ThinkofyouCookie } from 'renderer/utils';
import { recoilPersist } from 'recoil-persist';
import { LOCAL_STORAGE_KEY_AFTER_SALES_REGISTER_COMPLETE } from '../constants/index';
import { createNanoId } from 'common-utils';
import { notification } from 'antd/lib';
import React from 'react';
import { atom as JotaiAtom, useSetAtom, useAtomValue } from 'jotai';
var refreshTokenState = atom({
    key: 'refreshToken',
    default: LocalStorage.get(LOCAL_STORAGE_KEY_REFRESH_TOKEN) || ThinkofyouCookie.getInstance().get('refresh_token') // default value (aka initial value)
});
var accessTokenState = atom({
    key: 'accessToken',
    default: LocalStorage.get(LOCAL_STORAGE_KEY_ACCESS_TOKEN) // default value (aka initial value)
});
var mustPrefetchedDataState = atom({
    key: 'mustPrefetchedData',
    default: null
});
var globalHeaderState = atom({
    key: 'globalHeader',
    default: null
});
var hideStickBannerState = atom({
    key: 'hideStickBanner',
    default: getWithExpiry(LOCAL_STORAGE_KEY_HIDE_BANNER_VISIBLE)
});
var globalHeaderHeightState = selector({
    key: 'globalHeaderHeight',
    get: function (_a) {
        var get = _a.get;
        return (!get(hideStickBannerState) ? HEADER_HEIGHT + STICK_BANNER_HEIGHT : 60);
    }
});
var connectedQRScannersState = atom({
    key: 'connectedQRScanners',
    default: []
});
var persistAtom = recoilPersist({
    key: 'LOCAL_STORAGE_KEY_CALENDAR_SETTING'
}).persistAtom;
var calendarDisplayByManagerState = atom({
    key: 'calendarDisplayByManager',
    default: null,
    effects_UNSTABLE: [persistAtom]
});
var calendarDisplaySelector = selector({
    key: 'calendarDisplay/get',
    set: function (_a, newValue) {
        var _b;
        var get = _a.get, set = _a.set, reset = _a.reset;
        var mustData = get(mustPrefetchedDataState);
        var byManager = get(calendarDisplayByManagerState);
        set(calendarDisplayByManagerState, __assign(__assign({}, byManager), (_b = {}, _b[mustData.managerId] = newValue, _b)));
    },
    get: function (_a) {
        var get = _a.get;
        var mustData = get(mustPrefetchedDataState);
        var caledar = get(calendarDisplayByManagerState);
        if ((mustData === null || mustData === void 0 ? void 0 : mustData.managerId) && caledar && caledar[mustData.managerId]) {
            return caledar[mustData.managerId];
        }
        else {
            return {
                view: 'month',
                magnification: 100,
                managerIds: [],
                provider: [],
                types: [],
                deposits: []
            };
        }
    }
});
var scheduleModalState = atom({
    key: 'scheduleModal',
    default: null
});
var reservationStatusChangerModalState = atom({
    key: 'naverStatusChangerModal',
    default: null
});
// TODO: 마지막 설정하던 부분 인식하도록
var globalSettingModalState = atom({
    key: 'globalSettingModal',
    default: null
});
var salesModalState = atom({
    key: 'salesSettingModal',
    default: null
});
var salesRegister = recoilPersist({
    key: LOCAL_STORAGE_KEY_AFTER_SALES_REGISTER_COMPLETE
});
var salesRegisterOffOptionState = atom({
    key: 'salesRegisterOffOption',
    default: 'details',
    effects_UNSTABLE: [salesRegister.persistAtom]
});
var scheduleRegister = recoilPersist({
    key: LOCAL_STORAGE_KEY_AFTER_SCHEDULE_REGISTER_COMPLETE
});
var scheduleRegisterOffOptionState = atom({
    key: 'scheduleRegisterOffOption',
    default: 'details',
    effects_UNSTABLE: [scheduleRegister.persistAtom]
});
var courseListDrawer = recoilPersist({
    key: LOCAL_STORAGE_KEY_COURSE_LIST_DRAWER
});
var courseDrawerState = atom({
    key: 'courseListDrawer',
    default: {
        status: 'off',
    },
    effects_UNSTABLE: [courseListDrawer.persistAtom]
});
var naverBookingBlockingState = atom({
    key: 'naverBookingBlockingState',
    default: {}
});
var selectedScheduleState = JotaiAtom(null);
export var useSelectedSchedule = function () {
    var selectedSchedule = useAtomValue(selectedScheduleState);
    var setSelectedSchedule = useSetAtom(selectedScheduleState);
    return {
        selectedSchedule: selectedSchedule,
        setSelectedSchedule: setSelectedSchedule
    };
};
var initialValue = JotaiAtom([]);
export var useAddScheduleModal = function () {
    var setAnime = useSetAtom(initialValue);
    var add = React.useCallback(function (item) {
        console.time('Reservation Modal');
        var id = item.id || createNanoId();
        setAnime(function (items) {
            var isDuplicate = !!items.find(function (item) { return item.id === id; });
            if (isDuplicate) {
                notification.warning({ message: '이미 열려있는 예약창입니다.' });
                return items;
            }
            else {
                return items.concat(__assign(__assign({}, item), { id: id }));
            }
        });
    }, [setAnime]);
    return add;
};
export var useGetScheduleModals = function () {
    var anime = useAtomValue(initialValue);
    return anime;
};
export var useScheduleModal = function () {
    var setReservationDrawer = useSetAtom(initialValue);
    var add = function (item) {
        var id = item.id || createNanoId();
        setReservationDrawer(function (items) {
            var isDuplicate = !!items.find(function (item) { return item.id === id; });
            if (isDuplicate) {
                notification.warning({ message: '이미 열려있는 예약창입니다.' });
                return items;
            }
            else {
                return items.concat(__assign(__assign({}, item), { id: id }));
            }
        });
    };
    var remove = React.useCallback(function (id) {
        setReservationDrawer(function (items) {
            return (items || []).filter(function (item) { return item.id !== id; });
        });
    }, [setReservationDrawer]);
    var replace = React.useCallback(function (newItem) {
        setReservationDrawer(function (items) {
            return (items || []).map(function (item) {
                if (item.id === newItem.id) {
                    return newItem;
                }
                else {
                    return item;
                }
            });
        });
    }, [setReservationDrawer]);
    var getLastItem = React.useCallback(function (prevItems) {
        return prevItems ? prevItems[prevItems.length - 1] : null;
    }, []);
    var removeLastItem = React.useCallback(function () {
        setReservationDrawer(function (items) {
            var lastItem = getLastItem(items);
            return (items || []).filter(function (item) { return item.id !== lastItem.id; });
        });
    }, [getLastItem, remove]);
    var replaceFromLastItem = React.useCallback(function (newItem) {
        setReservationDrawer(function (items) {
            return (items || []).map(function (item, index) {
                if (items.length - 1 === index) {
                    return newItem;
                }
                else {
                    return item;
                }
            });
        });
    }, [getLastItem, remove]);
    return {
        add: add,
        remove: remove,
        replace: replace,
        replaceFromLastItem: replaceFromLastItem,
        removeLastItem: removeLastItem,
        getLastItem: getLastItem
    };
};
export var useSaleModal = function () {
    var _a = useRecoilState(Atoms.salesModalState), items = _a[0], setReservationDrawer = _a[1];
    var add = function (item) {
        var id = item.id || createNanoId();
        var isDuplicate = !!(items === null || items === void 0 ? void 0 : items.find(function (item) { return item.id === id; }));
        if (isDuplicate) {
            return notification.warning({ message: '이미 열려있는 매출창입니다.' });
        }
        else {
            setReservationDrawer((items || []).concat(__assign(__assign({}, item), { id: id })));
        }
    };
    var remove = React.useCallback(function (id) {
        setReservationDrawer(function (items) {
            return (items || []).filter(function (item) { return item.id !== id; });
        });
    }, [setReservationDrawer]);
    var replace = function (newItem) {
        var newItems = (items || []).map(function (item) {
            if (item.id === newItem.id) {
                return newItem;
            }
            else {
                return item;
            }
        });
        setReservationDrawer(newItems);
    };
    var replaceFromLastItem = function (newItem) {
        var lastItem = getLastItem();
        if (lastItem) {
            replace(__assign(__assign({}, newItem), { id: lastItem === null || lastItem === void 0 ? void 0 : lastItem.id }));
        }
    };
    var removeLastItem = function () {
        var lastItem = getLastItem();
        if (lastItem) {
            remove(lastItem.id);
        }
    };
    var getLastItem = function () {
        return items ? items[items.length - 1] : null;
    };
    return {
        add: add,
        remove: remove,
        replace: replace,
        replaceFromLastItem: replaceFromLastItem,
        items: items,
        removeLastItem: removeLastItem,
        getLastItem: getLastItem
    };
};
// 글로벌 타이머 객체 초기화
window.globalTimers = {};
// 타이머를 시작하는 함수
var startTimer = function (name) {
    console.log("Start Timer: ".concat(name));
    window.globalTimers[name] = { startTime: performance.now() };
};
// 타이머를 종료하고 지속 시간을 계산하는 함수
function checkpoint(name) {
    var timer = window.globalTimers[name];
    if (timer && timer.startTime) {
        var endTime = performance.now();
        var duration = endTime - timer.startTime;
        console.log("checkpoint ".concat(name, " duration: ").concat(duration, " ms"));
        return duration;
    }
    else {
        console.log("Timer '".concat(name, "' not started"));
        return null;
    }
}
// 타이머를 종료하고 지속 시간을 계산하는 함수
function endTimer(name) {
    var timer = window.globalTimers[name];
    if (timer && timer.startTime) {
        var endTime = performance.now();
        var duration = endTime - timer.startTime;
        window.globalTimers[name].duration = duration;
        console.log("END - ".concat(name, " duration: ").concat(duration, " ms"));
        return duration;
    }
    else {
        console.log("Timer '".concat(name, "' not started"));
        return null;
    }
}
export var Atoms = {
    accessTokenState: accessTokenState,
    refreshTokenState: refreshTokenState,
    globalHeaderState: globalHeaderState,
    hideStickBannerState: hideStickBannerState,
    globalHeaderHeightState: globalHeaderHeightState,
    connectedQRScannersState: connectedQRScannersState,
    scheduleModalState: scheduleModalState,
    globalSettingModalState: globalSettingModalState,
    salesModalState: salesModalState,
    mustPrefetchedDataState: mustPrefetchedDataState,
    calendarDisplaySelector: calendarDisplaySelector,
    salesRegisterOffOptionState: salesRegisterOffOptionState,
    scheduleRegisterOffOptionState: scheduleRegisterOffOptionState,
    courseDrawerState: courseDrawerState,
    naverBookingBlockingState: naverBookingBlockingState,
    reservationStatusChangerModalState: reservationStatusChangerModalState,
    startTimer: startTimer,
    endTimer: endTimer,
    checkpoint: checkpoint
};
