var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BaseModel } from './BaseModel';
import { StoreProductModel } from './StoreProductModel';
import { createUUID, floor, isEmpty, isEqual, sum } from 'renderer/utils';
import * as dayjs from 'dayjs';
import { ManagerModel, SALES_TYPES } from 'renderer/models';
import { CustomerModel } from './CustomerModel';
import { SalesHistoryModel } from './SalesHistoryModel';
import { COLORS } from 'renderer/styles';
import { CustomerGroupQuery, ManagerQuery, PaymentHistoryQuery, ProductQuery } from 'renderer/queries';
// PENDING -> CONFIRMED(승인)
//         -> CANCELLED(거절)
// CONFIRMED -> COMPLETED(시술 완료)
//           -> NOSHOW(노쇼)
// NOSHOW -> COMPLETE(완료)
//        -> CONFIRM(승인)
export var RESERVATION_STATUS;
(function (RESERVATION_STATUS) {
    RESERVATION_STATUS["PENDING"] = "pending";
    RESERVATION_STATUS["CONFIRMED"] = "confirm";
    RESERVATION_STATUS["NOSHOW"] = "noshow";
    RESERVATION_STATUS["NEED_REGISTER_SALES"] = "need_register_sales";
    RESERVATION_STATUS["COMPLETED"] = "completed";
    RESERVATION_STATUS["CANCELLED_CUSTOMR"] = "cancelled_customer";
    RESERVATION_STATUS["CANCELLED_STORE"] = "cancelled_store";
})(RESERVATION_STATUS || (RESERVATION_STATUS = {}));
var RESERVATION_FROM;
(function (RESERVATION_FROM) {
    RESERVATION_FROM["WEB"] = "web";
    RESERVATION_FROM["CRM"] = "crm";
    RESERVATION_FROM["KAKAO"] = "kakao";
    RESERVATION_FROM["NAVER"] = "naver";
})(RESERVATION_FROM || (RESERVATION_FROM = {}));
var RESERVATION_TYPE;
(function (RESERVATION_TYPE) {
    RESERVATION_TYPE["SCHEDULE"] = "schedule";
    RESERVATION_TYPE["RESERVATION"] = "reservation";
    RESERVATION_TYPE["OFF"] = "off";
})(RESERVATION_TYPE || (RESERVATION_TYPE = {}));
// const items = [
//   { label: '반복안함', value: { unit: 'none' } },
//   { label: '매일', value: { unit: 'week', data: { interval: 1, days: [0, 1, 2, 3, 4, 5, 6] } } },
//   { label: `주중 매일(월-금)`, value: { unit: 'week', data: { interval: 1, days: [1, 2, 3, 4, 5] } } },
//   { label: `매주 ${dayString}`, value: { unit: 'week', data: { interval: 1, days: [days] } } },
//   {
//     label: `매월 ${weekNumber}번째 ${dayString}`,
//     value: { unit: 'month', data: { interval: 1, th: weekNumber, days: [days] } }
//   },
//   {
//     label: `매월 ${dd}일`,
//     value: { unit: 'month', data: { interval: 1, dd } }
//   },
//   { label: `매년 ${startedAt.format('MM월DD일')}`, value: { unit: 'year', data: { interval: 1 } } }
// ];
var ScheduleModel = /** @class */ (function (_super) {
    __extends(ScheduleModel, _super);
    function ScheduleModel(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        if (props.storeProducts) {
            _this.storeProducts =
                ((_a = props.storeScheduleProducts) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                    var _a;
                    var product = (_a = props.storeProducts) === null || _a === void 0 ? void 0 : _a.find(function (product) { return product.id === item.storeProductId; });
                    return new StoreProductModel(product);
                })) ||
                    props.storeProducts.map(function (product) { return new StoreProductModel(product); }) ||
                    [];
        }
        if (props.manager) {
            _this.manager = new ManagerModel(props.manager);
        }
        if (props.customer) {
            _this.customer = new CustomerModel(props.customer);
        }
        return _this;
    }
    ScheduleModel.prototype.isIncludeStartAndEnd = function (date, exclusive) {
        if (exclusive === void 0) { exclusive = '[)'; }
        var _a = this.dateOption, startedAt = _a.startedAt, endedAt = _a.endedAt;
        return dayjs(date).isBetween(startedAt, endedAt, 'minute', exclusive);
    };
    ScheduleModel.prototype.isInDateStartAndEnd = function (date) {
        var _a = this.dateOption, startedAt = _a.startedAt, endedAt = _a.endedAt;
        return dayjs(date).isBetween(dayjs(startedAt), dayjs(endedAt), 'day', '[]');
    };
    Object.defineProperty(ScheduleModel.prototype, "isExternal", {
        get: function () {
            var _a, _b;
            return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.provider) && ((_b = this.data) === null || _b === void 0 ? void 0 : _b.provider) !== 'crm';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "isReservation", {
        get: function () {
            return this.type === ScheduleModel.RESERVATION_TYPE.RESERVATION;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "isOfficialNaverReservation", {
        get: function () {
            var _a;
            return !!((_a = this.data) === null || _a === void 0 ? void 0 : _a.isOfficial);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "disabledUpdateProducts", {
        get: function () {
            return this.isNPayUsed || !!this.salesHistoryId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "isNPayUsed", {
        get: function () {
            var _a;
            return !!(this.isOfficialNaverReservation && ((_a = this.data) === null || _a === void 0 ? void 0 : _a.isNPayUsed));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "isNaverPostPayment", {
        get: function () {
            return !!this.naverPostPaymentStatus;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "isRepeatSchedule", {
        get: function () {
            var _a, _b;
            return (this.type !== ScheduleModel.RESERVATION_TYPE.RESERVATION &&
                this.dateOption.repeat &&
                ((_b = (_a = this.dateOption) === null || _a === void 0 ? void 0 : _a.repeat) === null || _b === void 0 ? void 0 : _b.type) !== 'none');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "repeatString", {
        get: function () {
            return this.isRepeatSchedule ? ScheduleModel.getRepeatString(this.dateOption.repeat) : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "displayName", {
        get: function () {
            var _a, _b;
            return this.type === ScheduleModel.RESERVATION_TYPE.RESERVATION
                ? ((_a = this.customer) === null || _a === void 0 ? void 0 : _a.name)
                    ? (_b = this.customer) === null || _b === void 0 ? void 0 : _b.name
                    : this.customerId
                        ? '삭제된 고객'
                        : '미등록'
                : this.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "displayContent", {
        get: function () {
            return this.type === ScheduleModel.RESERVATION_TYPE.RESERVATION ? this.usingString : this.description;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "usingString", {
        get: function () {
            return this.type === ScheduleModel.RESERVATION_TYPE.RESERVATION
                ? isEmpty(this.storeProducts)
                    ? '시술내역 없음'
                    : this.storeProducts.map(function (product) { return product.nameWithGroupName; }).join(' / ')
                : '-';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "managerName", {
        get: function () {
            var _a;
            var name = (_a = this.manager) === null || _a === void 0 ? void 0 : _a.displayName;
            return name ? name : !this.managerId ? '담당자 없음' : '삭제된 직원';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "popoverTitle", {
        get: function () {
            return this.type !== ScheduleModel.RESERVATION_TYPE.RESERVATION ? this.name : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "statusToString", {
        get: function () {
            switch (this.status) {
                case ScheduleModel.RESERVATION_STATUS.PENDING:
                    return '예약대기';
                case ScheduleModel.RESERVATION_STATUS.CONFIRMED:
                case ScheduleModel.RESERVATION_STATUS.COMPLETED:
                    return this.salesHistoryId ? '매출등록' : '예약확정';
                case ScheduleModel.RESERVATION_STATUS.NOSHOW:
                    return this.salesHistoryId ? '매출등록' : '노쇼';
                case ScheduleModel.RESERVATION_STATUS.CANCELLED_CUSTOMR:
                    return '고객예약취소';
                case ScheduleModel.RESERVATION_STATUS.CANCELLED_STORE:
                    return '상점예약취소';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "isCancelled", {
        get: function () {
            return [
                ScheduleModel.RESERVATION_STATUS.CANCELLED_CUSTOMR,
                ScheduleModel.RESERVATION_STATUS.CANCELLED_STORE,
                ScheduleModel.RESERVATION_STATUS.NOSHOW
            ].includes(this.status);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "datesToString", {
        get: function () {
            var format = 'YYYY-MM-DD-(ddd) HH:mm';
            var _a = this.dateOption, startedAt = _a.startedAt, endedAt = _a.endedAt;
            return "".concat(dayjs(startedAt).format(format), " ~ ").concat(dayjs(endedAt).format('HH:mm'));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "isMadeFromClient", {
        get: function () {
            return !this.createdAt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "isPaidDeposit", {
        get: function () {
            var _this = this;
            var _a, _b, _c;
            return (((_a = this.data) === null || _a === void 0 ? void 0 : _a.deposit) &&
                ((_b = this.data) === null || _b === void 0 ? void 0 : _b.deposit) === sum(Object.keys(((_c = this.data) === null || _c === void 0 ? void 0 : _c.methods) || {}).map(function (key) { var _a; return ((_a = _this.data) === null || _a === void 0 ? void 0 : _a.methods[key]) || 0; })));
        },
        enumerable: false,
        configurable: true
    });
    ScheduleModel.prototype.getStartAndEndByDate = function (date) {
        var _a = this.dateOption, startedAt = _a.startedAt, endedAt = _a.endedAt;
        if (dayjs(date).isAfter(startedAt, 'date')) {
            startedAt = dayjs(date).startOf('day').toDate();
        }
        if (dayjs(date).isBefore(endedAt, 'date')) {
            endedAt = dayjs(date).endOf('day').toDate();
        }
        return { startedAt: startedAt, endedAt: endedAt };
    };
    Object.defineProperty(ScheduleModel.prototype, "color", {
        get: function () {
            var _this = this;
            var _a, _b;
            var status = function () {
                switch (true) {
                    case !!_this.salesHistoryId:
                        return COLORS.schedule3;
                    case _this.status === RESERVATION_STATUS.PENDING:
                        return COLORS.schedule1;
                    case _this.status === RESERVATION_STATUS.CONFIRMED:
                    case _this.status === RESERVATION_STATUS.COMPLETED:
                        return COLORS.schedule2;
                    case _this.status === RESERVATION_STATUS.NOSHOW:
                        return COLORS.schedule4;
                    case _this.status === RESERVATION_STATUS.NOSHOW:
                        return COLORS.schedule3;
                    case _this.status === RESERVATION_STATUS.CANCELLED_CUSTOMR:
                        return COLORS.schedule5;
                    case _this.status === RESERVATION_STATUS.CANCELLED_STORE:
                        return COLORS.schedule5;
                }
            };
            return {
                manager: ((_b = (_a = this.manager) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.color) || COLORS.staff1,
                status: status()
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "typeString", {
        get: function () {
            if (this.type === RESERVATION_TYPE.OFF) {
                return '휴무';
            }
            else if (this.type === RESERVATION_TYPE.RESERVATION) {
                return '예약';
            }
            else if (this.type === RESERVATION_TYPE.SCHEDULE) {
                return '일정';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "phone", {
        get: function () {
            var _a;
            if (this.type === RESERVATION_TYPE.SCHEDULE) {
                return ((_a = this.customer) === null || _a === void 0 ? void 0 : _a.phoneToString) || '-';
            }
            else {
                return '-';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "depositStatus", {
        get: function () {
            var _this = this;
            var _a, _b;
            var deposit = ((_a = this.data) === null || _a === void 0 ? void 0 : _a.deposit) || 0;
            var receivedDeposit = sum((_b = Object.keys(this.data.methods || {})) === null || _b === void 0 ? void 0 : _b.map(function (method) {
                return _this.data.methods[method] || 0;
            }));
            if (!deposit) {
                return 'none';
            }
            if (deposit === receivedDeposit) {
                return 'completed';
            }
            else if (!receivedDeposit && deposit !== receivedDeposit) {
                return 'pending';
            }
            else if (deposit > receivedDeposit) {
                return 'under';
            }
            else if (deposit < receivedDeposit) {
                return 'over';
            }
            return 'completed';
        },
        enumerable: false,
        configurable: true
    });
    ScheduleModel.createRepeatScheduleByTargetDay = function (target, item) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var targetDay = dayjs(target);
        if (targetDay.isBefore((_a = item.dateOption) === null || _a === void 0 ? void 0 : _a.startedAt, 'day')) {
            return;
        }
        var repeatEndedAt = ((_d = (_c = (_b = item.dateOption) === null || _b === void 0 ? void 0 : _b.repeat) === null || _c === void 0 ? void 0 : _c.expired) === null || _d === void 0 ? void 0 : _d.endedAt)
            ? // 브라우져 타임존에 맞추기 위한 변환
                new Date(dayjs((_g = (_f = (_e = item.dateOption) === null || _e === void 0 ? void 0 : _e.repeat) === null || _f === void 0 ? void 0 : _f.expired) === null || _g === void 0 ? void 0 : _g.endedAt).toISOString())
            : null;
        if (repeatEndedAt && targetDay.isAfter(repeatEndedAt, 'day')) {
            return;
        }
        var isExclude = (((_h = item.dateOption.repeat) === null || _h === void 0 ? void 0 : _h.excludes) || []).find(function (exclude) {
            return dayjs(target).isSame(exclude.startedAt, 'day');
        });
        if (!!isExclude) {
            return;
        }
        return item.createRepeatSchedule(targetDay);
    };
    ScheduleModel.prototype.createRepeatSchedule = function (targetDay) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var shouldCreate = false;
        var repeat = (_a = this.dateOption) === null || _a === void 0 ? void 0 : _a.repeat;
        var startedAt = ((_b = this.dateOption) === null || _b === void 0 ? void 0 : _b.startedAt) && dayjs((_c = this.dateOption) === null || _c === void 0 ? void 0 : _c.startedAt).startOf('day');
        var endedAt = dayjs(this.dateOption.endedAt);
        var interval = (_d = repeat.data) === null || _d === void 0 ? void 0 : _d.interval;
        var isOrigin = startedAt.isSame(targetDay, 'day');
        if (isOrigin) {
            shouldCreate = true;
        }
        else {
            if (repeat && startedAt && interval) {
                if (repeat.unit === 'year') {
                    var diff = targetDay.year() - startedAt.year();
                    if (diff % interval === 0) {
                        if (startedAt.date() === targetDay.date() && (startedAt === null || startedAt === void 0 ? void 0 : startedAt.month()) === targetDay.month()) {
                            shouldCreate = true;
                        }
                    }
                }
                else if (repeat.unit === 'month') {
                    var diff = targetDay.month() - startedAt.month();
                    if (diff % interval === 0) {
                        if (((_e = repeat.data) === null || _e === void 0 ? void 0 : _e.date) && ((_f = repeat.data) === null || _f === void 0 ? void 0 : _f.date) === targetDay.date()) {
                            shouldCreate = true;
                        }
                        else if ((_g = repeat.data) === null || _g === void 0 ? void 0 : _g.th) {
                            var day = ((_h = repeat === null || repeat === void 0 ? void 0 : repeat.data) === null || _h === void 0 ? void 0 : _h.days) && ((_j = repeat === null || repeat === void 0 ? void 0 : repeat.data) === null || _j === void 0 ? void 0 : _j.days[0]);
                            if (day || day === 0) {
                                var firstDayInMonth = targetDay.startOf('month').set('day', day);
                                var shouldMinus = firstDayInMonth.isSame(targetDay, 'month');
                                var shouldEqualDate = firstDayInMonth.add((((_k = repeat.data) === null || _k === void 0 ? void 0 : _k.th) - (shouldMinus ? 1 : 0)) * 7, 'day');
                                if (shouldEqualDate.isSame(targetDay) && shouldEqualDate.isSame(targetDay.startOf('month'), 'month')) {
                                    shouldCreate = true;
                                }
                            }
                        }
                    }
                }
                else if (repeat.unit === 'week') {
                    var diff = targetDay.diff(startedAt.subtract(1, 'day'), 'week');
                    if (diff % interval === 0) {
                        var days = ((_l = repeat.data) === null || _l === void 0 ? void 0 : _l.days) || [];
                        if (days.includes(targetDay.day())) {
                            shouldCreate = true;
                        }
                    }
                }
                else if (repeat.unit === 'day') {
                    var diff = targetDay.diff(startedAt, 'day');
                    if (diff % interval === 0) {
                        shouldCreate = true;
                    }
                }
            }
        }
        return (shouldCreate &&
            new ScheduleModel(__assign(__assign({}, this), { id: isOrigin ? this.id : createUUID(), dateOption: __assign(__assign({}, this.dateOption), { repeat: __assign(__assign({}, (_m = this.dateOption) === null || _m === void 0 ? void 0 : _m.repeat), { originId: this.id, origin: this }), startedAt: targetDay
                        .set('hour', dayjs((_o = this.dateOption) === null || _o === void 0 ? void 0 : _o.startedAt).hour())
                        .set('minute', dayjs((_p = this.dateOption) === null || _p === void 0 ? void 0 : _p.startedAt).minute())
                        .toISOString(), endedAt: targetDay.set('hour', endedAt.hour()).set('minute', endedAt.minute()).toISOString() }) })));
    };
    ScheduleModel.prototype.isFiltered = function (filterData) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var managerIds = filterData.managerIds, keyword = filterData.keyword, types = filterData.types, provider = filterData.provider, deposits = filterData.deposits;
        if (!isEmpty(managerIds)) {
            if (!managerIds.includes(this.managerId || -1)) {
                // 상점 휴무는 제외 담당자없음 필터에서 제외
                if (!((!this.managerId || this.managerId === -1) && this.type === RESERVATION_TYPE.OFF)) {
                    return true;
                }
            }
        }
        if (!isEmpty(provider)) {
            if (!provider.includes(((_a = this === null || this === void 0 ? void 0 : this.data) === null || _a === void 0 ? void 0 : _a.provider) || 'crm')) {
                return true;
            }
        }
        if (!isEmpty(types)) {
            if (!types.includes(this.type)) {
                if (this.type !== RESERVATION_TYPE.RESERVATION) {
                    return true;
                }
                else {
                    if (this.salesHistoryId) {
                        if (!types.includes('sales')) {
                            return true;
                        }
                    }
                    else {
                        if (!types.includes(this.status)) {
                            return true;
                        }
                    }
                }
            }
        }
        if (!isEmpty(deposits)) {
            var deposit = ((_b = this.data) === null || _b === void 0 ? void 0 : _b.deposit) || 0;
            var methodsSum = ((_c = this.data) === null || _c === void 0 ? void 0 : _c.methods)
                ? sum(Object.keys((_d = this.data) === null || _d === void 0 ? void 0 : _d.methods).map(function (key) { var _a; return (_a = _this.data) === null || _a === void 0 ? void 0 : _a.methods[key]; }))
                : 0;
            if (this.type === RESERVATION_TYPE.RESERVATION && deposit) {
                if (!methodsSum) {
                    if (!deposits.includes('pending')) {
                        return true;
                    }
                }
                if (methodsSum) {
                    if (methodsSum === deposit) {
                        if (!deposits.includes('completed')) {
                            return true;
                        }
                    }
                    else if (methodsSum > deposit) {
                        if (!deposits.includes('over')) {
                            return true;
                        }
                    }
                    else if (methodsSum < deposit) {
                        if (!deposits.includes('under')) {
                            return true;
                        }
                    }
                }
            }
            else {
                if (!deposits.includes('none')) {
                    return true;
                }
            }
        }
        if (keyword) {
            if (this.customer && (((_f = (_e = this.customer) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.includes(keyword)) || ((_h = (_g = this.customer) === null || _g === void 0 ? void 0 : _g.phone) === null || _h === void 0 ? void 0 : _h.includes(keyword)))) {
                return false;
            }
            if (((_j = this.description) === null || _j === void 0 ? void 0 : _j.includes(keyword)) || ((_k = this.name) === null || _k === void 0 ? void 0 : _k.includes(keyword))) {
                return false;
            }
            return true;
        }
        return false;
    };
    ScheduleModel.prototype.createNewScheduleFromRepeatSchedule = function (originSchedule, prevSchedule, onlyOrAfter) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var newItem = this;
        if (onlyOrAfter === 'all') {
            return {
                origin: __assign(__assign({}, originSchedule), { name: this.name, description: this.description, managerId: this.managerId })
            };
        }
        else if (onlyOrAfter === 'only') {
            newItem = __assign(__assign({}, newItem), { dateOption: __assign(__assign({}, newItem.dateOption), { repeat: null, originId: originSchedule.id }) });
            delete newItem.id;
            return {
                origin: __assign(__assign({}, originSchedule), { dateOption: __assign(__assign({}, originSchedule.dateOption), { repeat: __assign(__assign({}, (_a = originSchedule === null || originSchedule === void 0 ? void 0 : originSchedule.dateOption) === null || _a === void 0 ? void 0 : _a.repeat), { origin: null, excludes: (((_b = originSchedule === null || originSchedule === void 0 ? void 0 : originSchedule.dateOption) === null || _b === void 0 ? void 0 : _b.repeat.excludes) || []).concat({
                                startedAt: (_c = prevSchedule.dateOption) === null || _c === void 0 ? void 0 : _c.startedAt,
                                endedAt: (_d = prevSchedule.dateOption) === null || _d === void 0 ? void 0 : _d.endedAt
                            }) }) }) }),
                newItem: newItem
            };
        }
        else if (onlyOrAfter === 'after') {
            newItem = __assign(__assign({}, newItem), { dateOption: __assign(__assign({}, newItem.dateOption), { originId: originSchedule.id }) });
            delete newItem.id;
            return {
                origin: __assign(__assign({}, originSchedule), { dateOption: __assign(__assign({}, originSchedule.dateOption), { repeat: __assign(__assign({}, (_e = originSchedule === null || originSchedule === void 0 ? void 0 : originSchedule.dateOption) === null || _e === void 0 ? void 0 : _e.repeat), { origin: null, excludes: (((_f = originSchedule === null || originSchedule === void 0 ? void 0 : originSchedule.dateOption) === null || _f === void 0 ? void 0 : _f.repeat.excludes) || []).concat({
                                startedAt: (_g = prevSchedule.dateOption) === null || _g === void 0 ? void 0 : _g.startedAt,
                                endedAt: (_h = prevSchedule.dateOption) === null || _h === void 0 ? void 0 : _h.endedAt
                            }), expired: {
                                type: 'date',
                                // endedAt은 하루전으로 설정해야 해당 예약도 사라짐
                                endedAt: dayjs((_j = prevSchedule.dateOption) === null || _j === void 0 ? void 0 : _j.startedAt)
                                    .subtract(1, 'day')
                                    .toDate()
                            }, originId: originSchedule.id }) }) }),
                newItem: newItem
            };
        }
    };
    ScheduleModel.convertRepeatBySelectedDate = function (date, prevDate, repeat) {
        var _a, _b, _c;
        if (!repeat || (repeat === null || repeat === void 0 ? void 0 : repeat.unit) === 'none') {
            return { unit: 'none' };
        }
        if (dayjs(prevDate).isSame(date, 'day')) {
            return repeat;
        }
        if (repeat.unit === 'week' && (((_b = (_a = repeat.data) === null || _a === void 0 ? void 0 : _a.days) === null || _b === void 0 ? void 0 : _b.length) || 0) > 1) {
            return { unit: 'none' };
        }
        var weekNumber = dayjs(date).diff(dayjs(date).startOf('month'), 'weeks') + 1;
        var days = dayjs(date).day();
        var data = repeat.data;
        if ((repeat === null || repeat === void 0 ? void 0 : repeat.unit) === 'week') {
            data = {
                interval: data.interval,
                days: [days]
            };
        }
        else if ((repeat === null || repeat === void 0 ? void 0 : repeat.unit) === 'month') {
            if ((_c = repeat === null || repeat === void 0 ? void 0 : repeat.data) === null || _c === void 0 ? void 0 : _c.th) {
                data = {
                    interval: data.interval,
                    th: weekNumber,
                    days: [days]
                };
            }
            else {
                data = {
                    interval: data.interval,
                    date: dayjs(date).date()
                };
            }
        }
        else if ((repeat === null || repeat === void 0 ? void 0 : repeat.unit) === 'year') {
            data = {
                interval: data.interval,
                month: dayjs(date).month(),
                date: dayjs(date).date()
            };
        }
        return __assign(__assign({}, repeat), { data: data });
    };
    ScheduleModel.prototype.getSalesModalDataFromThis = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, schedule, isReservationFee, sales;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        schedule = this;
                        isReservationFee = [
                            RESERVATION_STATUS.NOSHOW,
                            RESERVATION_STATUS.CANCELLED_CUSTOMR,
                            RESERVATION_STATUS.CANCELLED_STORE
                        ].includes(this.status);
                        if (!(schedule.salesHistoryId && schedule.storeId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, PaymentHistoryQuery.getSalesById({
                                queryKey: [
                                    '',
                                    {
                                        storeId: schedule.storeId,
                                        salesHistoryId: schedule.salesHistoryId
                                    }
                                ],
                                meta: {}
                            })];
                    case 1:
                        sales = _a.sent();
                        data = {
                            fixedCustomerId: true,
                            isDirty: true,
                            type: isReservationFee ? 'reservationFee' : 'product',
                            data: sales
                        };
                        return [3 /*break*/, 3];
                    case 2:
                        data = {
                            fixedCustomerId: true,
                            isDirty: true,
                            type: isReservationFee ? 'reservationFee' : 'product',
                            data: this.createSalesModalDataFromThis()
                        };
                        _a.label = 3;
                    case 3: return [2 /*return*/, data];
                }
            });
        });
    };
    ScheduleModel.prototype.createSalesModalDataFromThis = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        var schedule = this;
        var naverPayInfo;
        var coupons = (_a = schedule.data) === null || _a === void 0 ? void 0 : _a.bookingCoupons;
        var addNaverPayPostPaid = 0;
        // 네이버페이가 있을경우
        var preNaverpay = 0;
        if (((_b = schedule.data) === null || _b === void 0 ? void 0 : _b.payments) || !isEmpty(coupons)) {
            naverPayInfo = {};
            var preNaverPay = schedule.naverPrePayment;
            var postNaverPay = schedule.naverPostPayment;
            if (preNaverPay) {
                naverPayInfo['pre'] = preNaverPay;
                preNaverpay = (preNaverPay.amount || 0) - (preNaverPay.refundedAmount || 0);
            }
            if (postNaverPay) {
                naverPayInfo['post'] = postNaverPay;
                // REGISTERED	결제등록
                // REQUESTED	결제요청
                // CHARGED	결제청구
                // CANCELED	결제요청취소
                // DEPOSIT_WAITING	무통장입금대기
                // DEPOSIT_WAITING_CANCELED	무통장입금대기취소
                // PAID	결제완료
                // REFUNDED	환불완료
                // REPAYMENT_REQUESTED	재결제요청
                // REFUNDED_BY_REPAYMENT	재결제로 인한 환불완료
                // COMPLETED	구매확정
                if (['COMPLETED', 'PAID', 'REFUNDED', 'REPAYMENT_REQUESTED', 'REFUNDED_BY_REPAYMENT'].includes(postNaverPay.status)) {
                    addNaverPayPostPaid = (postNaverPay.amount || 0) - (postNaverPay.refundedAmount || 0);
                }
            }
            if (!isEmpty(coupons)) {
                naverPayInfo['coupons'] = coupons;
            }
        }
        // 환불금을 제외하고 실제 네이버 페이로 받음 금액 포함
        var methods = SalesHistoryModel.getMethodsForForm(((_c = schedule.data) === null || _c === void 0 ? void 0 : _c.methods)
            ? __assign(__assign({}, (_d = schedule.data) === null || _d === void 0 ? void 0 : _d.methods), { naverPay: this.isOfficialNaverReservation
                    ? (preNaverpay || 0) + addNaverPayPostPaid
                    : ((_f = (_e = this.data) === null || _e === void 0 ? void 0 : _e.methods) === null || _f === void 0 ? void 0 : _f.naverPay) || 0 }) : null);
        // 최초 예약시 네이버페이로 받은 금액 (환불 제외 X, 현장결제 X)
        var scheduleDepositMethods = ((_g = schedule.data) === null || _g === void 0 ? void 0 : _g.methods)
            ? this.isOfficialNaverReservation
                ? __assign(__assign({}, (_h = schedule.data) === null || _h === void 0 ? void 0 : _h.methods), { naverPay: preNaverpay || 0 }) : (_j = schedule.data) === null || _j === void 0 ? void 0 : _j.methods
            : null;
        var manager = ManagerQuery.getLoginManagerInCache();
        var scheduleTime = (_k = schedule.dateOption) === null || _k === void 0 ? void 0 : _k.startedAt;
        var deposit = ((_l = schedule === null || schedule === void 0 ? void 0 : schedule.data) === null || _l === void 0 ? void 0 : _l.hasDeposit) ? (_m = schedule === null || schedule === void 0 ? void 0 : schedule.data) === null || _m === void 0 ? void 0 : _m.deposit : 0;
        var isReservationFee = [
            ScheduleModel.RESERVATION_STATUS.NOSHOW,
            ScheduleModel.RESERVATION_STATUS.CANCELLED_CUSTOMR,
            ScheduleModel.RESERVATION_STATUS.CANCELLED_STORE
        ].includes(schedule.status);
        return {
            scheduleId: schedule.id,
            scheduleStatus: schedule.status,
            scheduleManagerId: (schedule.managerId || (manager === null || manager === void 0 ? void 0 : manager.id)),
            scheduleDeposit: deposit,
            scheduleDepositMethods: scheduleDepositMethods || {},
            scheduleNaverPayInfo: naverPayInfo || null,
            scheduleTime: scheduleTime,
            customerId: schedule.customerId,
            customer: schedule.customer,
            paidAt: (_o = schedule.dateOption) === null || _o === void 0 ? void 0 : _o.startedAt,
            methods: methods,
            type: isReservationFee ? SALES_TYPES.RESERVATION_FEE : null,
            createCustomerPoint: this.customer
                ? CustomerGroupQuery.calculateWillCreateCustomerPoint(methods, (_p = this.customer) === null || _p === void 0 ? void 0 : _p.customerGroupId, schedule.storeId)
                : 0,
            usingHistory: isReservationFee
                ? [
                    {
                        id: createUUID(),
                        amount: 1,
                        price: deposit,
                        salesPerManagers: [
                            {
                                type: isReservationFee ? 'reservationFee' : null,
                                managerId: schedule.managerId || (manager === null || manager === void 0 ? void 0 : manager.id),
                                shareRate: 100,
                                sharePrice: deposit
                            }
                        ]
                    }
                ]
                : ((_q = schedule.storeProducts) === null || _q === void 0 ? void 0 : _q.filter(function (product) {
                    var prevProduct = ProductQuery.getProductByIdFromCache(schedule.storeId, product.id);
                    return !!prevProduct;
                }).map(function (product) { return ({
                    id: createUUID(),
                    productId: product.id,
                    amount: 1,
                    price: product.price,
                    salesPerManagers: [
                        {
                            managerId: schedule.managerId || (manager === null || manager === void 0 ? void 0 : manager.id),
                            shareRate: 100,
                            sharePrice: product.price || 0
                        }
                    ]
                }); })) || []
        };
    };
    ScheduleModel.prototype.getNaverRefundPolicy = function (now) {
        var _a, _b, _c, _d;
        now = dayjs(now)
            .add(-(((_a = this.data) === null || _a === void 0 ? void 0 : _a.refundTimeOffset) || 0), 'hour')
            .startOf('day');
        var diff = dayjs(this.dateOption.startedAt).startOf('day').diff(now, 'day');
        var policy = (_c = (_b = this.data) === null || _b === void 0 ? void 0 : _b.refundPolicy) === null || _c === void 0 ? void 0 : _c.find(function (policy) {
            return policy.baseDay >= diff;
        });
        var timeoffsetHour = dayjs()
            .startOf('day')
            .add(((_d = this.data) === null || _d === void 0 ? void 0 : _d.refundTimeOffset) || 0, 'day')
            .hour();
        return policy
            ? {
                rate: policy.rate,
                message: "\uC774\uC6A9 ".concat(timeoffsetHour ? policy.baseDay + 1 : policy.baseDay, " \uC77C\uC804").concat(timeoffsetHour ? " ".concat(timeoffsetHour, "\uC2DC") : '', "\uAE4C\uC9C0\uB294 ").concat(policy.rate, "% \uD658\uBD88")
            }
            : {
                rate: 100,
                message: '환불 정책에 따라 100% 환불'
            };
    };
    Object.defineProperty(ScheduleModel.prototype, "naverPostPaymentStatus", {
        get: function () {
            var postPayment = this.naverPostPayment;
            if (!postPayment) {
                return false;
            }
            return postPayment === null || postPayment === void 0 ? void 0 : postPayment.status;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "naverPostPaymentItems", {
        get: function () {
            var _a;
            return ((_a = this.naverPostPayment) === null || _a === void 0 ? void 0 : _a.items) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "naverPostPayment", {
        get: function () {
            var _a, _b, _c;
            var postPayment = (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.payments) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return item.moment === 'POST'; });
            var lastItem = (_c = postPayment === null || postPayment === void 0 ? void 0 : postPayment.filter(function (item) { return item.status !== 'CANCELED'; })) === null || _c === void 0 ? void 0 : _c.pop();
            console.log('lastItem', lastItem);
            return lastItem || (postPayment === null || postPayment === void 0 ? void 0 : postPayment.pop());
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "naverPrePayment", {
        get: function () {
            var _a, _b;
            var prePayment = (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.payments) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.moment === 'PRE'; });
            return prePayment;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScheduleModel.prototype, "availableRequestNaverPostRePayment", {
        get: function () {
            return dayjs().isBefore(dayjs(this.dateOption.startedAt).add(1, 'day').startOf('day').add(17, 'hour'));
        },
        enumerable: false,
        configurable: true
    });
    ScheduleModel.RESERVATION_TYPE = RESERVATION_TYPE;
    ScheduleModel.RESERVATION_STATUS = RESERVATION_STATUS;
    ScheduleModel.RESERVATION_FROM = RESERVATION_FROM;
    ScheduleModel.getDefaultDateOption = function (unit) {
        var minute = dayjs().minute();
        var startedAt;
        if (unit === 10) {
            var target = floor(minute + unit, -1);
            startedAt = dayjs().startOf('hour').add(target, 'minute').startOf('minute');
        }
        else {
            if (minute >= 30) {
                startedAt = dayjs().add(1, 'hour').startOf('hour');
            }
            else {
                startedAt = dayjs().startOf('hour').add(30, 'minute').startOf('minute');
            }
        }
        return { startedAt: startedAt.toDate(), endedAt: startedAt.add(unit, 'minute').toDate() };
    };
    ScheduleModel.getRepeatString = function (repeat) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        var dayString = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
        if (!repeat || repeat.unit === 'none') {
            return '반복안함';
        }
        var result = '';
        if (repeat.unit === 'day') {
            if (((_a = repeat.data) === null || _a === void 0 ? void 0 : _a.interval) === 1) {
                result = '매일';
            }
            else {
                result = "".concat((_b = repeat.data) === null || _b === void 0 ? void 0 : _b.interval, "\uC77C\uB9C8\uB2E4");
            }
        }
        else if (repeat.unit === 'week') {
            var days = ((_c = repeat.data) === null || _c === void 0 ? void 0 : _c.days) || [];
            var front = "".concat(((_d = repeat.data) === null || _d === void 0 ? void 0 : _d.interval) === 1 ? '매주' : "".concat((_e = repeat.data) === null || _e === void 0 ? void 0 : _e.interval, "\uC8FC \uAC04\uACA9\uC73C\uB85C"));
            var back = days.length === 7
                ? '매일'
                : isEqual(days, [1, 2, 3, 4, 5])
                    ? '주중(월-금)'
                    : days.map(function (day) { return dayString[day]; }).join(',');
            result = "".concat(front, " ").concat(back);
        }
        else if (repeat.unit === 'month') {
            var front = "".concat(((_f = repeat.data) === null || _f === void 0 ? void 0 : _f.interval) === 1 ? '매월' : "".concat((_g = repeat.data) === null || _g === void 0 ? void 0 : _g.interval, "\uAC1C\uC6D4 \uAC04\uACA9\uC73C\uB85C"));
            var back = '';
            if ((_h = repeat.data) === null || _h === void 0 ? void 0 : _h.date) {
                back = "".concat((_j = repeat.data) === null || _j === void 0 ? void 0 : _j.date, "\uC77C");
            }
            else if ((_k = repeat.data) === null || _k === void 0 ? void 0 : _k.th) {
                back = "".concat((_l = repeat.data) === null || _l === void 0 ? void 0 : _l.th, "\uBC88\uC9F8 ").concat(dayString[(_m = repeat.data) === null || _m === void 0 ? void 0 : _m.days[0]]);
            }
            result = "".concat(front, " ").concat(back);
        }
        else if (repeat.unit === 'year') {
            var front = "".concat(((_o = repeat.data) === null || _o === void 0 ? void 0 : _o.interval) === 1 ? '매년' : "".concat((_p = repeat.data) === null || _p === void 0 ? void 0 : _p.interval, "\uB144 \uAC04\uACA9\uC73C\uB85C"));
            var back = "".concat(((_q = repeat.data) === null || _q === void 0 ? void 0 : _q.month) + 1, "\uC6D4 ").concat((_r = repeat.data) === null || _r === void 0 ? void 0 : _r.date, "\uC77C");
            result = "".concat(front, " ").concat(back);
        }
        if ((_s = repeat.expired) === null || _s === void 0 ? void 0 : _s.endedAt) {
            return "".concat(result, "(").concat(dayjs((_t = repeat.expired) === null || _t === void 0 ? void 0 : _t.endedAt).format('YYYY-MM-DD'), "\uAE4C\uC9C0)");
        }
        else {
            return "".concat(result);
        }
    };
    return ScheduleModel;
}(BaseModel));
export { ScheduleModel };
export var NAVER_PAY_STATUS;
(function (NAVER_PAY_STATUS) {
    NAVER_PAY_STATUS["REGISTERED"] = "\uACB0\uC81C\uB4F1\uB85D";
    NAVER_PAY_STATUS["REQUESTED"] = "\uACB0\uC81C\uC694\uCCAD";
    NAVER_PAY_STATUS["CHARGED"] = "\uACB0\uC81C\uCCAD\uAD6C";
    NAVER_PAY_STATUS["DEPOSIT_WAITING"] = "\uBB34\uD1B5\uC7A5\uC785\uAE08\uB300\uAE30";
    NAVER_PAY_STATUS["CANCELED"] = "\uACB0\uC81C\uC694\uCCAD\uCDE8\uC18C";
    NAVER_PAY_STATUS["DEPOSIT_WAITING_CANCELED"] = "\uBB34\uD1B5\uC7A5\uC785\uAE08\uB300\uAE30\uCDE8\uC18C";
    NAVER_PAY_STATUS["PAID"] = "\uACB0\uC81C\uC644\uB8CC";
    NAVER_PAY_STATUS["REFUNDED"] = "\uD658\uBD88\uC644\uB8CC";
    NAVER_PAY_STATUS["REPAYMENT_REQUESTED"] = "\uC7AC\uACB0\uC81C\uC694\uCCAD";
    NAVER_PAY_STATUS["REFUNDED_BY_REPAYMENT"] = "\uC7AC\uACB0\uC81C\uB85C \uC778\uD55C \uD658\uBD88\uC644\uB8CC";
    NAVER_PAY_STATUS["COMPLETED"] = "\uAD6C\uB9E4\uD655\uC815";
})(NAVER_PAY_STATUS || (NAVER_PAY_STATUS = {}));
