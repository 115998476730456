var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation, useQuery } from 'react-query';
import { ScheduleModel, SalesPerManagerModel } from 'renderer/models';
import { deepConvertKeysToCamelCase, groupBy, LambdaClient, RestClient, setScheduleToLocal, sum, uniqBy, usePager } from 'renderer/utils';
import { QUERY_CACHE } from 'renderer/queries';
import { sortBy } from 'renderer/utils';
import dayjs from 'dayjs';
import notification from 'antd/lib/notification';
import { DATE_FORMAT } from 'renderer/constants';
import { isArray } from 'lodash';
export var checkAvailableReservationByDate = function (_, storeId, params) {
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/schedules/available"), { params: params })
        .then(function (result) {
        var _a, _b, _c, _d;
        return ({
            schedule: ((_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.schedule) === null || _b === void 0 ? void 0 : _b.map(function (item) { return new ScheduleModel(item); })) || [],
            reservation: ((_d = (_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.reservation) === null || _d === void 0 ? void 0 : _d.map(function (item) { return new ScheduleModel(item); })) || []
        });
    });
};
var getSchedulesByDate = function (options) {
    var params = options.queryKey[1];
    var storeId = params.storeId, rest = __rest(params, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/schedules"), { params: rest })
        .then(function (result) {
        setScheduleToLocal(result.data);
        return sortBy(result.data, 'dateOption.startedAt').map(function (item) { return new ScheduleModel(item); });
    });
};
var getPendingSchedules = function (options) {
    var params = options.queryKey[1];
    var storeId = params.storeId, rest = __rest(params, ["storeId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/schedules/pending"), { params: rest })
        .then(function (result) {
        return {
            after: sortBy(result.data.after || [], 'dateOption.startedAt').map(function (item) { return new ScheduleModel(item); }),
            before: sortBy(result.data.before || [], 'dateOption.startedAt').map(function (item) { return new ScheduleModel(item); })
        };
    });
};
var getSchedulesByCustomerId = function (options) {
    var _a = options.queryKey, _ = _a[0], pagerOptions = _a[1];
    var storeId = pagerOptions.storeId, customerId = pagerOptions.customerId, rest = __rest(pagerOptions, ["storeId", "customerId"]);
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/schedules/customers/").concat(customerId), { params: rest })
        .then(function (result) { return result.data; });
};
var createSchedule = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/schedules"), payload)
        .then(function (result) { return new ScheduleModel(result.data); });
};
var sendScheduleSMS = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/schedules/").concat(payload.id, "/sms"), payload)
        .then(function (result) { return new ScheduleModel(result.data); });
};
var updateSchedule = function (payload) {
    return RestClient.getInstance()
        .put("/stores/".concat(payload.storeId, "/schedules/").concat(payload.id), payload)
        .then(function (result) { return new ScheduleModel(result.data); });
};
var updateNaverSchedule = function (payload) {
    var storeId = payload.storeId, bizItemId = payload.bizItemId, bookingId = payload.bookingId, rest = __rest(payload, ["storeId", "bizItemId", "bookingId"]);
    return LambdaClient.getInstance()
        .put("/naver/".concat(storeId, "/bizItems/").concat(bizItemId, "/bookings/").concat(bookingId), payload)
        .then(function (result) { return new ScheduleModel(deepConvertKeysToCamelCase(result.data)); });
};
var createNewRepeatSchedule = function (payload) {
    var _a, _b;
    var storeId = ((_a = payload.origin) === null || _a === void 0 ? void 0 : _a.storeId) || ((_b = payload.newItem) === null || _b === void 0 ? void 0 : _b.storeId);
    return RestClient.getInstance()
        .post("/stores/".concat(storeId, "/schedules/repeat"), payload)
        .then(function (result) { return ({
        origin: new ScheduleModel(result.data.origin),
        newItem: new ScheduleModel(result.data.newItem)
    }); });
};
var deleteSchedule = function (params) {
    return RestClient.getInstance()
        .remove("/stores/".concat(params.storeId, "/schedules/").concat(params.id))
        .then(function (result) { return new ScheduleModel(result.data); });
};
var mutationConfig = {
    onSuccess: function (res, data) {
        var _a, _b;
        var scheduleQueries = QUERY_CACHE.getQueriesData(['schedules']);
        // const scheduleQueries = QUERY_CACHE.setQueriesData();
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['pendingSchedules']);
        /**
         * TODO: 예약(일정, 휴무) 수정 완료
         */
        QUERY_CACHE.invalidateQueries(['schedulesByCustomerId']);
        if (res.messageResult && (((_a = res.messageResult) === null || _a === void 0 ? void 0 : _a.new) || ((_b = res.messageResult) === null || _b === void 0 ? void 0 : _b.cancel))) {
            QUERY_CACHE.invalidateQueries(['storePoint']);
        }
        // 반복 예약 수정일 경우
        if (res === null || res === void 0 ? void 0 : res.newItem) {
            QUERY_CACHE.invalidateQueries(['schedules']);
        }
        else {
            scheduleQueries.map(function (query) {
                var _a, _b;
                var queryKey = query[0];
                var params = queryKey && queryKey[1];
                if (params && params.startedAt && params.endedAt) {
                    var startedAt = dayjs(params.startedAt);
                    var endedAt = dayjs(params.endedAt);
                    if (dayjs((_a = res.dateOption) === null || _a === void 0 ? void 0 : _a.startedAt).isBetween(startedAt, endedAt) ||
                        dayjs((_b = res.dateOption) === null || _b === void 0 ? void 0 : _b.endedAt).isBetween(startedAt, endedAt)) {
                        var prevSchedules = query[1];
                        if (isArray(prevSchedules)) {
                            var newSchedules = prevSchedules
                                .filter(function (item) { return item.id !== res.id; })
                                .concat(res.deletedAt ? null : new ScheduleModel(res))
                                .filter(Boolean);
                            var realNewSchedueles = sortBy(newSchedules, 'dateOption.startedAt').map(function (item) { return new ScheduleModel(item); });
                            QUERY_CACHE.setQueryData(['schedules', params], realNewSchedueles);
                        }
                    }
                }
            });
        }
        var isUpdated = !dayjs(res.createdAt).isSame(res.updatedAt, 'second');
        notification.success({
            message: "".concat(res.type === ScheduleModel.RESERVATION_TYPE.RESERVATION ? '예약' : '일정', " ").concat(isUpdated ? '변경' : '등록', " \uC644\uB8CC")
        });
    },
    onError: function (error) {
        // notification.error({ message: error.message });
        // throw new Error(error.message);
    }
};
export var useSchedulesByCustomerId = function (params, config) {
    return usePager('schedulesByCustomerId', params, getSchedulesByCustomerId, ScheduleModel, config);
};
export var useSchedules = function (params, config) {
    return useQuery(['schedules', params], getSchedulesByDate, __assign({}, config));
};
export var useGetReservationById = function (params, config) {
    return useQuery(['schedules', params], function (options) {
        var params = options.queryKey[1];
        var storeId = params.storeId, id = params.id;
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/schedules/").concat(id))
            .then(function (result) { return new ScheduleModel(result.data); });
    }, __assign({}, config));
};
export var usePendingSchedules = function (params, config) {
    return useQuery(['pendingSchedules', params], getPendingSchedules, config);
};
export var useSchedulesForTransfer = function (params, config) {
    return useQuery(['transferSchedules', params], function (options) {
        var params = options.queryKey[1];
        var storeId = params.storeId, rest = __rest(params, ["storeId"]);
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/schedules/transfer"), { params: rest })
            .then(function (result) {
            var _a;
            return (_a = result.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new ScheduleModel(item); });
        });
    }, config);
};
export var getSchedulesFromCache = function (date) {
    var items = QUERY_CACHE.getQueryCache().findAll(['schedules']);
    var pop = items.pop();
    var item = !!date ? items.find(function (_a) {
        var queryKey = _a.queryKey, state = _a.state;
        var _b = queryKey[1], startedAt = _b.startedAt, endedAt = _b.endedAt;
        return dayjs(date).isBetween(startedAt, endedAt) && !!state.data;
    }) : pop;
    return uniqBy((item || pop).state.data, 'id');
};
export var useCreateSchedule = function (config) {
    return useMutation(createSchedule, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateSchedule = function (config) {
    return useMutation(updateSchedule, __assign(__assign({}, mutationConfig), config));
};
export var useNaverUpdateSchedule = function (config) {
    return useMutation(updateNaverSchedule, __assign(__assign({}, mutationConfig), config));
};
export var useCreateNewRepeatSchedule = function (config) {
    return useMutation(createNewRepeatSchedule, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteSchedule = function (config) {
    return useMutation(deleteSchedule, __assign(__assign({}, mutationConfig), config));
};
export var useSendScheduleSMS = function (config) {
    return useMutation(sendScheduleSMS, __assign(__assign(__assign({}, mutationConfig), { onSuccess: function () {
            QUERY_CACHE.invalidateQueries(['storePoint']);
        } }), config));
};
var getHolidayFromAPI = function (options) {
    var yyyy = options.queryKey[1];
    return RestClient.getInstance()
        .get("/external/holiday?yyyy=".concat(yyyy))
        .then(function (response) {
        var _a;
        return (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(function (item) {
            var locdate = "".concat(item.locdate);
            var dateString = "".concat(locdate.substring(0, 4), "-").concat(locdate.substring(4, 6), "-").concat(locdate.substring(6, 8));
            return __assign(__assign({}, item), { date: dayjs(dateString) });
        });
    })
        .catch(function (erro) { return console.log(erro); });
};
export var useHoliday = function (yyyy, config) {
    return useQuery(['holiday', yyyy], getHolidayFromAPI, __assign(__assign({}, config), { staleTime: 60 * 1000 * 24 }));
};
export var getHoliday = function (date) {
    var _a;
    var yyyy = dayjs(date).format('YYYY');
    var items = QUERY_CACHE.getQueryCache().find(['holiday', yyyy]);
    var holiday = (_a = items === null || items === void 0 ? void 0 : items.state) === null || _a === void 0 ? void 0 : _a.data;
    return (holiday || []).find(function (item) { return item.date.isSame(date, 'date'); });
};
export var useGetSalesStat = function (params, config) {
    return useQuery(['salesStat', params], function (options) {
        var params = options.queryKey[1];
        var storeId = params.storeId, rest = __rest(params, ["storeId"]);
        return RestClient.getInstance()
            .get("/stores/".concat(storeId, "/schedules/sales"), { params: rest })
            .then(function (result) {
            var _a;
            return groupBy((_a = result.data) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                return new SalesPerManagerModel(item);
            }), function (item) {
                return dayjs(item.paidAt).format(DATE_FORMAT);
            });
        });
    }, __assign(__assign({}, config), { staleTime: 60 * 1000 * 5 }));
};
export var getSalesStatFromCache = function (startedAt, managerId) {
    var items = [];
    QUERY_CACHE.getQueryCache()
        .findAll(['salesStat'])
        .map(function (item) {
        var _a;
        items = items.concat.apply(items, Object.values(((_a = item.state) === null || _a === void 0 ? void 0 : _a.data) || {}));
    });
    return sum(uniqBy(items, 'id').map(function (spm) {
        if (dayjs(spm.createdAt).isSame(startedAt, 'day')) {
            return !managerId || spm.managerId === managerId ? spm.paidPrice : 0;
        }
        return 0;
    }));
};
export var getSalesTotalAndOff = function (startedAt, managerId) {
    var total = getSalesStatFromCache(startedAt, managerId);
    var schedules = getSchedulesFromCache(startedAt);
    var offList = schedules
        .filter(function (off) { return off.type === ScheduleModel.RESERVATION_TYPE.OFF; })
        .map(function (off) {
        if (off.isRepeatSchedule) {
            return ScheduleModel.createRepeatScheduleByTargetDay(startedAt, off);
        }
        else {
            return off;
        }
    })
        .filter(function (off) {
        var _a;
        return off &&
            (!managerId || off.managerId === managerId) &&
            ((_a = off === null || off === void 0 ? void 0 : off.dateOption) === null || _a === void 0 ? void 0 : _a.startedAt) &&
            dayjs(off.dateOption.startedAt).isSame(startedAt, 'day');
    }) || [];
    return { total: total, offList: offList };
};
export var getHolidayInfo = function (targetDate) {
    var holiday = getHoliday(targetDate);
    return (holiday === null || holiday === void 0 ? void 0 : holiday.dateName) || dayjs(targetDate).format('dd') === '일';
};
export var updateOrDeleteSchedule = function (params) { return __awaiter(void 0, void 0, Promise, function () {
    var status, rest, result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                status = params.status, rest = __rest(params, ["status"]);
                if (!(params.status === 'delete')) return [3 /*break*/, 2];
                return [4 /*yield*/, deleteSchedule(rest)];
            case 1:
                result = _a.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, updateSchedule(params)];
            case 3:
                result = _a.sent();
                _a.label = 4;
            case 4:
                QUERY_CACHE.invalidateQueries(['schedules']);
                QUERY_CACHE.invalidateQueries(['pendingSchedules']);
                QUERY_CACHE.invalidateQueries(['schedulesByCustomerId']);
                return [2 /*return*/, result];
        }
    });
}); };
export var blockNaverCalendar = function (effectedDates, store, managers) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, LambdaClient.getInstance().post("/crawler/blockNaverAvailableTime", effectedDates.map(function (targetDate) { return ({
                targetDate: targetDate,
                storeId: store.id
            }); }), {
                timeout: 90000
            })];
    });
}); };
var bitToHour = function (bits) {
    var day = dayjs().startOf('day');
    bits = bits.split('');
    if (bits.length === 24) {
        bits = bits.map(function (bit, index) {
            return "".concat(day.add(index * 60, 'minute').format('HH:mm'), "~").concat(day
                .add((index + 1) * 60, 'minute')
                .format('HH:mm'), ": ").concat(bit === '0' ? '불가' : '가능');
        });
    }
    else if (bits.length === 48) {
        bits = bits.map(function (bit, index) {
            return "".concat(day.add(index * 30, 'minute').format('HH:mm'), "~").concat(day
                .add((index + 1) * 30, 'minute')
                .format('HH:mm'), ": ").concat(bit === '0' ? '불가' : '가능');
        });
    }
    else {
        console.log('에러!');
    }
    return bits;
};
