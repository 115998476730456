var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
import { set, ThinkofyouCookie } from 'renderer/utils';
import { API_URL, API_TIMEOUT, PATH_SIGNOUT, LOCAL_STORAGE_KEY_ACCESS_TOKEN, LOCAL_STORAGE_KEY_REFRESH_TOKEN } from 'renderer/constants';
import { LocalStorage, SessionStorage } from './localstorage';
import { PATH_SIGNIN } from '../constants/path';
import { withScope as withSentryScope, captureException } from '@sentry/react';
var SYMBOL_INSTANCE = Symbol('singleton');
var RestClient = /** @class */ (function () {
    /**
     * Creates an instance of ApiClient.
     *
     * @memberOf ApiClient
     */
    function RestClient(defaultOptions) {
        var _this = this;
        var deviceUnique = LocalStorage.getDeviceUnique();
        this.client = axios.create(__assign({ baseURL: API_URL, timeout: API_TIMEOUT, headers: { 'Content-Type': 'application/json', 'Authorization-Device-Unique': deviceUnique } }, defaultOptions));
        this.client.interceptors.response.use(function (res) { return res; }, function (error) { return __awaiter(_this, void 0, void 0, function () {
            var status, isSignupPage, refreshToken, message, response, err_1;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            return __generator(this, function (_o) {
                switch (_o.label) {
                    case 0:
                        status = error.response ? error.response.status : null;
                        if (!(status === 401)) return [3 /*break*/, 4];
                        isSignupPage = (_a = location.pathname) === null || _a === void 0 ? void 0 : _a.includes('signup');
                        refreshToken = LocalStorage.get(LOCAL_STORAGE_KEY_REFRESH_TOKEN) ||
                            ThinkofyouCookie.getInstance().get('refresh_token') ||
                            SessionStorage.get('SESSION_STORAGE_KEY_REFRESH_TOKEN');
                        message = (_c = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message;
                        if (message === null || message === void 0 ? void 0 : message.includes('Invalid credentials')) {
                            captureException(new Error(JSON.stringify({
                                message: '프론트 로그아웃-Invalid credentials',
                                refreshToken: refreshToken,
                                deviceUnique: deviceUnique,
                                headers: (_e = (_d = this === null || this === void 0 ? void 0 : this.client) === null || _d === void 0 ? void 0 : _d.defaults) === null || _e === void 0 ? void 0 : _e.headers
                            })));
                            return [2 /*return*/, (location.href = PATH_SIGNIN)];
                        }
                        // Invalid credentials
                        if (!isSignupPage && (message === 'REFRESH_TOKEN_EXPIRED' || !refreshToken)) {
                            captureException(new Error(JSON.stringify({
                                message: '프론트 로그아웃-REFRESH_TOKEN_EXPIRED',
                                refreshToken: refreshToken,
                                deviceUnique: deviceUnique,
                                headers: (_g = (_f = this === null || this === void 0 ? void 0 : this.client) === null || _f === void 0 ? void 0 : _f.defaults) === null || _g === void 0 ? void 0 : _g.headers
                            })));
                            return [2 /*return*/, (location.href = PATH_SIGNOUT)];
                        }
                        if (isSignupPage) {
                            return [2 /*return*/, Promise.resolve(null)];
                        }
                        _o.label = 1;
                    case 1:
                        _o.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.client.post('/manager/accesstoken', { refreshToken: refreshToken })];
                    case 2:
                        response = _o.sent();
                        error.config.headers['Authorization'] = 'Bearer ' + ((_h = response === null || response === void 0 ? void 0 : response.data) === null || _h === void 0 ? void 0 : _h.accessToken);
                        this.setAccessToken((_j = response === null || response === void 0 ? void 0 : response.data) === null || _j === void 0 ? void 0 : _j.accessToken);
                        LocalStorage.set(LOCAL_STORAGE_KEY_ACCESS_TOKEN, (_k = response === null || response === void 0 ? void 0 : response.data) === null || _k === void 0 ? void 0 : _k.accessToken);
                        return [2 /*return*/, this.client.request(error.config)];
                    case 3:
                        err_1 = _o.sent();
                        location.href = PATH_SIGNOUT;
                        captureException(new Error(JSON.stringify({
                            message: '프론트 로그아웃-generate new token failed',
                            refreshToken: refreshToken,
                            deviceUnique: deviceUnique,
                            headers: (_m = (_l = this === null || this === void 0 ? void 0 : this.client) === null || _l === void 0 ? void 0 : _l.defaults) === null || _m === void 0 ? void 0 : _m.headers,
                            err: err_1
                        })));
                        console.log('generate new token failed', err_1);
                        return [3 /*break*/, 4];
                    case 4:
                        withSentryScope(function (scope) {
                            var _a;
                            // scope를 설정할 수 있는 함수 안에서 실행됩니다.
                            scope.setTags({
                                // 태그를 설정합니다. 여기서는 API URL을 태그로 추가합니다.
                                api: (_a = error.response) === null || _a === void 0 ? void 0 : _a.config.url,
                            });
                            // 에러를 Sentry에 캡처합니다.
                            captureException(error);
                        });
                        return [2 /*return*/, Promise.reject(error)];
                }
            });
        }); });
    }
    RestClient.prototype.get = function (path, config) {
        return this.wrapRequest(this.client.get(path, config));
    };
    RestClient.prototype.post = function (path, data, config) {
        return this.wrapRequest(this.client.post(path, data, config));
    };
    RestClient.prototype.put = function (path, body, config) {
        return this.wrapRequest(this.client.put(path, body, config));
    };
    RestClient.prototype.remove = function (path, config) {
        return this.wrapRequest(this.client.delete(path, config));
    };
    RestClient.prototype.setErrorHandler = function (handler) {
        this.errorHandler = handler;
    };
    RestClient.prototype.wrapRequest = function (promise) {
        var _this = this;
        return Promise.resolve(promise).catch(function (error) {
            if (_this.errorHandler) {
                return Promise.resolve(_this.errorHandler(error));
            }
            throw new Error(error.response.data.message);
        });
    };
    RestClient.prototype.setAccessToken = function (token) {
        set(this.client.defaults, "headers.Authorization", token ? "Bearer ".concat(token) : null);
    };
    RestClient.prototype.getInternalClient = function () {
        return this.client;
    };
    RestClient.getInstance = function () {
        var instanceDescriptor = Object.getOwnPropertyDescriptor(this, SYMBOL_INSTANCE);
        var instance = instanceDescriptor ? instanceDescriptor.value : new this();
        if (!instanceDescriptor) {
            Object.defineProperty(this, SYMBOL_INSTANCE, { value: instance });
        }
        return instance;
    };
    return RestClient;
}());
export { RestClient };
